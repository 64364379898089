<template>
  <login :titulo="perfilLabel"></login>
</template>

<script>
import login from '@/components/login/login'
import Config from '@/config'
export default {
  name: 'LoginIndex',
  components: {
    login,
  },
  data() {
    return {
      perfil: '',
      perfilLabel: '',
    }
  },
  created() {
    this.setaPerfil()
    document.title = `${Config.NOME_SISTEMA} - ${this.perfilLabel}`
  },
  methods: {
    setaPerfil() {
      const urlProfile = window.location.href

      if (urlProfile.includes('gestao')) {
        this.perfil = 'gestao'
        this.perfilLabel = 'Gestão'
      } else if (urlProfile.includes('consignataria')) {
        this.perfil = 'consignataria'
        this.perfilLabel = 'Consignatária'
      } else if (urlProfile.includes('servidor')) {
        this.perfil = 'servidor'
        this.perfilLabel = 'Servidor'
      } else {
        this.perfil = 'servidor'
        this.perfilLabel = 'Servidor'
      }
    },
  },
}
</script>
