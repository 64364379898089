export default class RoleService {
  constructor(http) {
    this._http = http
  }

  async getAll() {
    const { data } = await this._http.get('/api/roles/list')

    return data
  }

  async getOneByid(id) {
    const { data } = await this._http.get(`/api/roles/${id}`)

    return data
  }

  async salvarRole(role) {
    if (role.id) {
      return await this._http.put('/api/roles/', role)
    } else {
      return await this._http.post('/api/roles/', role)
    }
  }

  async delete(id) {
    return await this._http.delete(`/api/roles/${id}`)
  }

  async rolesDoUsuarioLogado() {
    const { data } = await this._http.get(`/api/roles/usuariologado`)

    return data
  }
}
